<template>
  <base-site
    :title="titleText"
    :show-button="!!reportPositionId"
    button-icon="trash"
    button-look="secondary-light"
    @button-action="openModal"
  >
    <spinner v-if="reportPositionLoading" />

    <template v-else>
      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="info" />
          </div>
          Allgemein
        </base-heading>
        <FormKit type="date" name="date" id="date" label="Datum*" validation="required" v-model="date" />

        <custom-fields ref="customFields"
                       :entity="CustomFieldEntity.MATERIAL_REPORT_POSITION"
                       :entity-id="existingReportPosition?.id ?? ''"
                       :custom-field-values="existingReportPosition?.customFieldValues" />
        </base-container>

      <transport-locations-card :existing-description="existingReportPosition?.description ?? ''"
                                @receive-locations="receiveLocations"
                                class="mb-betweenElements" />

      <transport-info-card :existing-report-position="existingReportPosition"
                           @update-price="receivePriceInfos"
                           class="mb-betweenElements" />

      <div class="flex md:flex-row flex-col">
        <base-button v-if="description" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
          <spinner-small v-if="requesting" />
          <div v-else>{{ submitText }}</div>
        </base-button>
        <base-button :class="`md:ml-auto md:mx-0 mx-betweenElements md:w-buttonXLarge ${description ? 'mt-8 md:mt-0' : ''}`"
                     look="secondary-light" @click="onCancel">
          Abbrechen
        </base-button>
      </div>

      <confirmation-modal
        ref="confirmDeletionModal"
        title="Transport Löschen"
        confirm-text="Löschen"
        @on-confirm="onDelete"
      >
        Soll der Transport wirklich gelöscht werden?
      </confirmation-modal>
    </template>
  </base-site>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import router from '@/router'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import Spinner from '@/components/generel/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import { DateConverter } from '@/client/DateConverter'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import TransportLocationsCard from '@/components/materialReportPosition/transportLocationsCard.vue'
import TransportInfoCard from '@/components/materialReportPosition/transportInfoCard.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import CustomFields from '@/components/generel/CustomFields.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type { ReportPosition, ReportPositionRequest } from '@/model/ReportPosition'
import { ReportType } from '@/model/Type'
import { UrlData } from '@/client/UrlData'
import type { Chapter } from '@/model/Chapter'

const TRANSPORT_REPORT = 'Transport'
const route = useRoute()

const requesting = ref(false)
const reportPositionLoading = ref(true)
const reportPositionId = route.params.id as string
const subprojectId = route.query.subprojectId as string
const projectId = route.query.projectId as string
const existingReportPosition = ref<ReportPosition>()
const date = ref(DateConverter.getCurrentLocalDateISO())

const chapterId = ref<string>(route.query.chapterId as string);
const chapters = (route.query.chapters ? UrlData.toData<Chapter[]>(route.query.chapters as string) : []);

const titleText = ref(TRANSPORT_REPORT)
const submitText = ref<string>()

const description = ref('')

const amount = ref(0)
const selectedUnitId = ref('')
const unitPrice = ref<number | undefined>(0)
const total = ref<number | undefined>(0);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null)
const confirmDeletionModal = ref(ConfirmationModal)

const $toast = useToast()

function receiveLocations(descriptionText: string) {
  description.value = descriptionText
}

function receivePriceInfos(infos: { amount: number; unitId: string; unitPrice: number | undefined; total: number | undefined }) {
  amount.value = infos.amount;
  selectedUnitId.value = infos.unitId;
  unitPrice.value = infos.unitPrice;
  total.value = infos.total;
}

async function onSubmit() {
  if (description.value == '') {
    $toast.error('Keine Lade- oder Abladeort hinzugefügt')
    return
  }
  if (amount.value == 0 || !selectedUnitId.value || unitPrice.value == 0) {
    $toast.error('Preisangaben nicht korrekt')
    return
  }
  if (!subprojectId) {
    $toast.error('Kein Rapport zugewiesen')
    console.error('subproject not selected!')
    return
  }
  if (reportPositionId) await onUpdate()
  else await onCreate()
}

async function onCreate() {
  if (requesting.value) return
  requesting.value = true
  const response = await API.createDataObject<ReportPosition, ReportPositionRequest>(
    PathSegment.REPORT_POSITIONS,
    {
      invoiceId: subprojectId,
      date: date.value ? DateConverter.convertToUnixTimestamp(date.value) : undefined,
      description: description.value,
      chapterId: chapterId.value == '' ? undefined : chapterId.value,
      chargeable: true,
      entries: [{
        description: undefined,
        quantity: amount.value,
        unitId: selectedUnitId.value,
        unitPrice: unitPrice.value,
        total: total.value
      }],
      reportType: ReportType.MATERIAL,
      customFieldValues: getCustomFieldValues()
    },
    TRANSPORT_REPORT
  )
  if (response) router.go(-1)
  requesting.value = false
}

async function onUpdate() {
  if (requesting.value) return
  if (!reportPositionId) {
    $toast.error('Aktuallisieren nicht möglich, keine Raport ID vorhanden')
    console.error('Update failed: ReportPositionId not set')
    return
  }

  requesting.value = true
  const response = await API.updateDataObject<
    ReportPosition,
    ReportPositionRequest
  >(
    PathSegment.REPORT_POSITIONS,
    {
      id: reportPositionId,
      invoiceId: subprojectId,
      date: DateConverter.convertToUnixTimestamp(date.value),
      description: description.value,
      chapterId: chapterId.value == '' ? undefined : chapterId.value,
      chargeable: true,
      entries: [{
        id: existingReportPosition.value?.entries[0].id,
        description: undefined,
        quantity: amount.value,
        unitId: selectedUnitId.value,
        unitPrice: unitPrice.value,
        total: total.value
      }],
      reportType: ReportType.MATERIAL,
      customFieldValues: getCustomFieldValues()
    },
    TRANSPORT_REPORT
  )
  if (response) router.go(-1)
  requesting.value = false
}

async function loadReportPosition() {
  const response = await API.getDataObject<ReportPosition>(
    PathSegment.REPORT_POSITIONS,
    reportPositionId
  )
  if (!response) return
  existingReportPosition.value = response
  date.value = response.date ? DateConverter.convertToLocalDateISO(response.date) : ''
  description.value = response.description ?? ''
  chapterId.value = response.chapterId ?? '';
  reportPositionLoading.value = false
}

async function onDelete() {
  const success = await API.deleteDataObject(
    PathSegment.REPORT_POSITIONS,
    reportPositionId,
    TRANSPORT_REPORT
  )
  if (success) router.go(-1)
  reportPositionLoading.value = false
}

function onCancel() {
  router.go(-1)
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal()
  }
}

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues()
}

function getChapter() {
  return chapters.find(c => c.id == chapterId.value)
}

watch(() => chapterId.value, newVal => {
  if (!newVal) return;
  const selectedChapter = getChapter();
  titleText.value = `Rapportposition${selectedChapter ? ` - ${selectedChapter.name}` : ''}`;
}, {
  immediate: true
});

onMounted(async () => {
  if (reportPositionId) {
    // edit
    submitText.value = 'Speichern'
    await loadReportPosition()
  } else {
    // create
    submitText.value = 'Erfassen'
    reportPositionLoading.value = false
  }
})
</script>

<style scoped></style>