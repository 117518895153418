<template>

  <base-site :title="titleText" :show-button="!!invoiceId" button-icon="trash" button-look="secondary-light" @button-action="openModal">
    <base-container class="mb-betweenElements">
      <spinner v-if="loading" />
      <div v-else>
        <FormKit type="text" name="name" id="name" label="Name des Rapports*" validation="required" v-model="subprojectName" />
        <FormKit type="number" number inputmode="decimal" step="any" name="costLimit" id="costLimit" label="Kostendach" :min="0" v-model="subprojectCostLimit" />

        <custom-fields ref="customFields"
                       :entity="CustomFieldEntity.SUBPROJECT"
                       :entity-id="invoice?.id ?? ''"
                       :custom-field-values="invoice?.customFieldValues" />

        <base-heading type="h2" class="pb-1 mt-5 !mb-0">Status*</base-heading>
        <base-container class="md:mb-0 mb-betweenElements !rounded-sm">
          <div class="flex flex-wrap justify-between">
            <button v-for="status of Type.getAllStatusesOrderByPriority()" :key="status.id"
                    :class="`flex-grow border-2 rounded-md px-10 text-bodyMediumBold py-7 sm:py-6 mr-3 last:mr-0 mb-3`"
                    :style="`color: ${status.displayColor}; border-color: ${subprojectStatusId == status.id ? status.displayColor : '#f5f5ff'}`" @click="onChangeStatus(status.id)">
              {{status.name}}
            </button>
          </div>
        </base-container>

        <dropdown label="Skonto & Mehrwertsteuer">
          <div class="flex flex-row flex-wrap mt-3 overflow-hidden">
            <base-container class="md:mr-betweenElements flex-grow md:mb-0 mb-betweenElements !rounded-sm" type="inner">
              <FormKit type="checkbox" label="Skonto" v-model="skontoCheckbox" />
              <div class="flex md:flex-row flex-col">
                <div class="md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" step="any" label="Skonto in %" :disabled="!skontoCheckbox" v-model="skonto"/></div>
                <FormKit type="date" name="date" id="date" label="Skonto Ablaufdatum" :disabled="!skontoCheckbox" v-model="skontoExpiration" />
              </div>
            </base-container>
            <base-container class="flex-grow !rounded-sm" type="inner">
              <div class="text-bodyMediumBold mb-1">Mehrwertssteuer in Preise</div>
              <radio-select
                :options="[
                { label: 'Exklusive', value: VatType.EXCLUSIVE },
                { label: 'Inklusive', value: VatType.INCLUSIVE },
                { label: 'Steuerfrei', value: VatType.VAT_FREE }
              ]"
                :init-option="vatOption"
                @on-select="(value: VatType) => vatOption = value"
              />
            </base-container>
          </div>
        </dropdown>

        <chapter-editor ref="chapterEditor" title="Phasen" :initial-chapters="chapters" />

      </div>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Rapport löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll der Rapport wirklich gelöscht werden?</confirmation-modal>
    </base-container>

    <div v-if="!loading" class="flex md:flex-row flex-col">
      <base-button look="primary" :disabled="!validate()" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{ submitText }}</div>
      </base-button>
      <base-button look="secondary-light" class="md:mx-0 md:mt-0 mt-betweenElements mx-betweenElements md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>
  </base-site>
</template>

<script setup lang="ts">
import { API, PathSegment } from '@/client/axios'
import { type Invoice, type InvoiceRequest, VatType } from '@/model/Invoice'
import { Type } from '@/model/Type'
import { useRoute } from 'vue-router'
import router, { routeNames } from '@/router'
import { onMounted, ref } from 'vue'
import BaseSite from '@/components/base/BaseSite.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import { DateConverter } from '@/client/DateConverter'
import CustomFields from '@/components/generel/CustomFields.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import Spinner from '@/components/generel/Spinner.vue'
import RadioSelect from '@/components/generel/RadioSelect.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseHeading from '@/components/base/BaseHeading.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import { FormValidator, Validation } from '@/client/FormValidator'
import Dropdown from '@/components/generel/Dropdown.vue'
import type { ChapterRequest } from '@/model/Chapter'
import { UrlData } from '@/client/UrlData'
import ChapterEditor from '@/components/invoice/ChapterEditor.vue'

const SUBPROJECT = 'Rapport';
const route = useRoute();

const loading = ref(false);
const requesting = ref(false);
const invoiceId = route.params.id as string;
const invoice = ref<Invoice>();
const projectId = route.query.projectId as string;
const offerId = route.query.offerId as string;
const costLimit = +(route.query.costLimit as string);
const name = route.query.name as string;
const titleText = ref('Rapport');
const submitText = ref<string>();

const subprojectName = ref<string>(name);
const subprojectCostLimit = ref(costLimit ?? 0);
const skontoCheckbox = ref(false);
const skonto = ref(2);
const skontoExpiration = ref('');
const subprojectStatusId = ref<string>(Type.getAllStatusesOrderByPriority()[0].id);

const chapterEditor = ref(ChapterEditor);
const chapters = ref<ChapterRequest[]>(route.query.chapters ? UrlData.toData<ChapterRequest[]>(route.query.chapters as string) : []);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

const vatOption = ref(TenantSettings.get.pricesIncludingVat ? VatType.INCLUSIVE : VatType.EXCLUSIVE);

async function onSubmit() {
  if (!validate()) return;
  if (!projectId && !invoice.value) {
    console.error('Invoice or project id is not set!');
    return;
  }

  if (projectId) await onCreate();
  else await onUpdate();
}

async function onCreate() {
  if (requesting.value) return;
  if (!chapterEditor.value) return;
  const subprojectRequet: InvoiceRequest = {
    projectId: projectId,
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    chapters: chapterEditor.value.getChapters().map((c: ChapterRequest) => {return {name: c.name, type: c.reportType, icon: c.icon, index: c.index}}),
    customFieldValues: getCustomFieldValues(),
    offerId: offerId,
    vatType: vatOption.value
  }
  requesting.value = true
  const response = await API.createDataObject<Invoice, InvoiceRequest>(
    PathSegment.INVOICES,
    subprojectRequet,
    SUBPROJECT
  );
  if (response) router.push({ name: routeNames.PROJECT, params: { id: response.projectId } })
  requesting.value = false
}

async function onUpdate() {
  if (requesting.value) return;
  if (!chapterEditor.value) return;
  const subprojectRequet: InvoiceRequest = {
    id: invoiceId,
    projectId: invoice.value?.projectId ?? '',
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    chapters: chapterEditor.value.getChapters(),
    customFieldValues: getCustomFieldValues(),
    offerId: offerId,
    vatType: vatOption.value,
  }
  requesting.value = true;
  const response = await API.updateDataObject<Invoice, InvoiceRequest>(PathSegment.INVOICES, subprojectRequet, SUBPROJECT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.INVOICES, invoiceId, SUBPROJECT);
  if (success) {
    router.go(-1);
  }
}

function onChangeStatus(id: string) {
  subprojectStatusId.value = id;
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

async function loadSubproject() {
  loading.value = true;
  const response = await API.getDataObject<Invoice>(PathSegment.INVOICES, invoiceId);
  if (!response) return;
  invoice.value = response;
  subprojectName.value = response.name;
  subprojectCostLimit.value = response.costLimit;
  subprojectStatusId.value = response.statusId;
  skontoCheckbox.value = response.skonto ? response.skonto != 0 : false;
  skonto.value = response.skonto ?? 0;
  skontoExpiration.value = response.skontoExpiration ? DateConverter.convertToLocalDateISO(response.skontoExpiration) : '';
  vatOption.value = response.vatType;
  chapters.value = response.chapters;
  loading.value = false;
}

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

function validate() {
  return FormValidator.validate([
    {value: subprojectName.value, validation: Validation.REQUIRED},
  ])
}

onMounted(async () => {
  if (!invoiceId && !projectId) {
    console.error('Invoice or project id is not set!');
    return;
  }

  if (invoiceId) {
    titleText.value = 'Rapport brearbeiten';
    submitText.value = 'Rapport speichern';
    await loadSubproject();
  } else {
    if (chapters.value.length === 0) {
      chapters.value = TenantSettings.get.chapters.map(c => {
        return {
          name: c.name,
          reportType: c.reportType,
          icon: c.icon,
          index: c.index,
        }
      });
    }
    titleText.value = 'Rapport erstellen';
    submitText.value = 'Rapport erfassen';
  }
});

</script>

<style scoped>

</style>
