import { defaultConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/themes'
import formkitTheme from './formkit.theme.reportpro';
import { de } from "@formkit/i18n";
import { createAutoHeightTextareaPlugin } from '@formkit/addons'

export default defaultConfig({
  locales: { de },
  locale: "de",
  config: {
    classes: generateClasses(formkitTheme),
  },
  messages: {
    de: {
      validation: {
        required({ name }) {
          return `*Pflichtfeld`;
        }
      },
      ui: {
        incomplete: 'Einige Felder sind nicht oder inkorrekt ausgefüllt.'
      }
    }
  },
  plugins: [
    createAutoHeightTextareaPlugin(),
  ]
}) 
