<template>
  <div v-if="isVisible" class="modal-container">
    <div class="modal-small-content w-modalWidth">

      <!-- Title-->
      <div class="p-5 mb-2 text-title">Positionen kopieren</div>

      <!-- Content -->
      <div class="flex flex-row items-center p-betweenElements mt-15 sm:mt-0">
        <div class="w-full">
          <FormKit type="date" label="Datum" v-model="date" />
        </div>
        <button class="ml-betweenElements hover:text-primary cursor-pointer transition-colors" @click="setDateToday">
          <phosphor-icon icon="calendar-dot" />
        </button>
      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0">
        <div class="flex">
          <base-button class="flex-1 m-8" look="secondary" @click="closeModal">
            Abbrechen
          </base-button>

          <base-button class="flex-1 m-8" @click="onConfirm">
            <spinner-small v-if="requesting" />
            <span v-else>Kopieren</span>
          </base-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'
import type { OfferPosition } from '@/model/OfferPosition'
import { DateConverter } from '@/client/DateConverter'
import { API, PathSegment } from '@/client/axios'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type { ReportPositionRequest } from '@/model/ReportPosition'
import type { Invoice } from '@/model/Invoice'

const props = defineProps<{
  invoice: Invoice | undefined;
}>();

const emits = defineEmits(['on-success', 'on-fail']);

const isVisible = ref(false);

const date = ref<string | undefined>();
let positions: OfferPosition[] = [];

const requesting = ref(false);
const openModal = (offerPositions: OfferPosition[]): void => {
  positions = offerPositions;
  date.value = undefined;
  isVisible.value = true;
};

const closeModal = (): void => {
  isVisible.value = false;
};

function setDateToday() {
  date.value = DateConverter.getCurrentLocalDateISO()
}

async function onConfirm() {
  if (!props.invoice?.id) {
    console.error('No linked invoice to copy');
    return;
  }
  if (!validateChapters(positions)) return;

  requesting.value = true;

  const reportPositions: ReportPositionRequest[] = positions.map(position => {
    return {
      invoiceId: props.invoice?.id ?? '',
      reportType: position.reportType,
      date: date.value ? DateConverter.convertToUnixTimestamp(date.value) : undefined,
      description: position.description,
      chapterId: mapChapter(position),
      chargeable: true,
      entries: position.entries.map(entry => {
        return {
          description: entry.description,
          quantity: entry.quantity,
          unitId: entry.unitId,
          unitPrice: entry.unitPrice
        }})
    };
  })

  try {
    const response = await API.post(`${PathSegment.REPORT_POSITIONS}/list`, reportPositions);
    if (!response) {
      requesting.value = false;
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  }

  requesting.value = false;
  emits('on-success');
  closeModal();
}

function mapChapter(position: OfferPosition): string | undefined {
  if (!position.chapterId || !props.invoice) return;

  const chapterName = props.invoice.offer?.chapters.find(c => c.id == position.chapterId)?.name;
  if (!chapterName) return;
  
  return props.invoice.chapters.find(c => c.name == chapterName)?.id;
}

function validateChapters(positions: OfferPosition[]): boolean {
  if (!props.invoice) {
    emits('on-fail', 'Keine Rechung verknüpft');
    return false;
  }

  for (const position of positions) {
    const chapterName = props.invoice.offer?.chapters.find(c => c.id == position.chapterId)?.name;
    if (!chapterName) {
      emits('on-fail', `Eine Position ist keiner Phase zugeordnet`);
      return false;
    }
    const invoiceChapter = props.invoice.chapters.find(c => c.name == chapterName)?.id;
    if (!invoiceChapter) {
      emits('on-fail', `Phase ${chapterName} in Rechnung nicht vorhanden`);
      return false;
    }
  }
  return true;
}

defineExpose({
  openModal
});

</script>

<style scoped>

</style>