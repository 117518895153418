<template>

  <base-site title="Benutzungszweck auswählen">
    <base-container>

      <div v-if="KeycloakService.isAdmin()">
        <div v-if="!constructionSelected">
          <div class="flex flex-row mb-betweenElements">
            <button :class="`${selected == 0 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2 mr-betweenElements`" @click="onSelect(0)">
              <div class="flex flex-row items-center mb-5">
                <base-icon class="mr-5">handyman</base-icon>
                <p class="text-bodyMediumBold">Dienstleistungen</p>
                <base-icon v-if="selected == 0" class="ml-auto">check</base-icon>
              </div>
              <p class="text-bodySmallNormal text-left">Ihr Geschäft bezieht sich auf Dienstleistungen</p>
            </button>
            <button :class="`${selected == 1 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2`" @click="onSelect(1)">
              <div class="flex flex-row items-center mb-5">
                <base-icon class="mr-5">local_shipping</base-icon>
                <p class="text-bodyMediumBold">Transport</p>
                <base-icon v-if="selected == 1" class="ml-auto">check</base-icon>
              </div>
              <p class="text-bodySmallNormal text-left">Ihr Geschäft bezieht sich speziell auf Transporte oder Lieferungen</p>
            </button>
          </div>

          <base-button @click="onSubmit">
            Weiter
          </base-button>
        </div>

        <div v-else>
          <div class="flex flex-row mb-betweenElements">
            <button :class="`${orientationSelected == 0 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2 mr-betweenElements`" @click="onOrientationSelect(0)">
              <div class="flex flex-row items-center mb-5">
                <base-icon class="mr-5">assignment</base-icon>
                <p class="text-bodyMediumBold">Projektorientiert</p>
                <base-icon v-if="orientationSelected == 0" class="ml-auto">check</base-icon>
              </div>
              <p class="text-bodySmallNormal text-left">Sie möchten für einen Kunden mehrere Projekte erfassen können, in denen Sie dann Rapporte, Rechnungen und Offerten erfassen können</p>
            </button>
            <button :class="`${orientationSelected == 1 ? 'selected' : 'selectable-md'} rounded-md p-7 w-1/2`" @click="onOrientationSelect(1)">
              <div class="flex flex-row items-center mb-5">
                <base-icon class="mr-5">groups</base-icon>
                <p class="text-bodyMediumBold">Kundenorientiert</p>
                <base-icon v-if="orientationSelected == 1" class="ml-auto">check</base-icon>
              </div>
              <p class="text-bodySmallNormal text-left">Sie möchten direkt auf dem Kunden Rapporte, Rechnungen und Offerten erfassen können</p>
            </button>
          </div>

          <base-button @click="onSubmitWithOrientation">
            Weiter
          </base-button>
        </div>
      </div>


      <div v-else>
        <p class="text-bodyMediumBold text-grey-dark mb-11 mt-11">Ihre Firma wurde noch nicht erstellt. Bitte melden Sie sich als Administrator an.</p>
        <base-button @click="KeycloakService.logout()">Logout</base-button>
      </div>

    </base-container>
  </base-site>

</template>

<script setup lang="ts">

import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { KeycloakService } from '@/service/keycloakService'
import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { API, PathSegment } from '@/client/axios'
import type Settings from '@/model/Settings'
import { UseCase } from '@/model/Settings'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import { TenantSettings } from '@/stores/TenantSettings'
import { Currency, ReportType } from '@/model/Type'

const router = useRouter();
const selected = ref<number>(2);
const orientationSelected = ref<number>(2);
const constructionSelected = ref(false);

async function onSubmit() {
  if (selected.value == 2) return;
  if (selected.value == 0) {
    constructionSelected.value = true;
    return;
  }
  const response = await API.updateDataObject<Settings>(PathSegment.SETTINGS, getSelectedSettings());
  if (!response) return;
  TenantSettings.setSettings(response);
  await router.push({name: routeNames.CREATE_COMPANY});
}

async function onSubmitWithOrientation() {
  if (selected.value == 2) return;
  const response = await API.updateDataObject<Settings>(PathSegment.SETTINGS, getSelectedSettings());
  if (!response) return;
  TenantSettings.setSettings(response);
  await router.push({name: routeNames.CREATE_COMPANY});
}

function onSelect(value: number) {
  selected.value = value;
}

function onOrientationSelect(value: number) {
  orientationSelected.value = value;
}

function getSelectedSettings(): Settings {
  switch (selected.value) {
    case 0: return getConstructionSettings();
    case 1: return getTransportSettings();
    default: throw Error("No Selection has been made");
  }
}

function getConstructionSettings(): Settings {
  return {
    tenant: KeycloakService.realm,
    defaultCurrency: Currency.CHF,
    pricesIncludingVat: false,
    distanceTopLogo: 0,
    distanceLogoAddress: 30,
    logoHeight: 45,
    signatureHeight: 45,
    distanceBetweenAddresses: 50,
    paymentDeadline: 30,
    skontoRuntime: 10,
    invoiceTitle: "Rechnung",
    invoiceHeader: undefined,
    invoiceFooter: undefined,
    invoiceWorkTableTitle: "Arbeiten",
    invoiceMaterialTableTitle: "Material",
    offerTitle: "Offerte",
    offerHeader: undefined,
    offerFooter: undefined,
    offerWorkTableTitle: "Arbeiten",
    offerMaterialTableTitle: "Material",
    aiLanguage: "de",
    aiPrompt: "Arbeitsrapport. Rechtschreibung korrigiert. Komplette Sätze, keine Zeilenumbrüche",
    updatedAt: 0,
    useCase: orientationSelected.value == 0 ? UseCase.CONSTRUCTION : UseCase.CONSTRUCTION_CO,
    chapters: [
      {
        name: 'Arbeiten',
        reportType: ReportType.WORK,
        index: 0,
        icon: 'hammer'
      },
      {
        name: 'Material',
        reportType: ReportType.MATERIAL,
        index: 1,
        icon: 'package'
      }
    ]
  }
}

function getTransportSettings(): Settings {
  return {
    tenant: KeycloakService.realm,
    defaultCurrency: Currency.CHF,
    pricesIncludingVat: false,
    distanceTopLogo: 0,
    distanceLogoAddress: 30,
    logoHeight: 45,
    signatureHeight: 45,
    distanceBetweenAddresses: 50,
    paymentDeadline: 30,
    skontoRuntime: 10,
    invoiceTitle: "Rechnung",
    invoiceHeader: undefined,
    invoiceFooter: undefined,
    invoiceWorkTableTitle: "Arbeiten",
    invoiceMaterialTableTitle: "Transporte;;;Ladeort;;;Abladeort",
    offerTitle: "Offerte",
    offerHeader: undefined,
    offerFooter: undefined,
    offerWorkTableTitle: "Arbeiten",
    offerMaterialTableTitle: "Material",
    aiLanguage: "de",
    aiPrompt: "Namen von Schweizer Ortschaften",
    updatedAt: 0,
    useCase: UseCase.TRANSPORT,
    chapters: [
      {
        name: 'Transporte',
        reportType: ReportType.MATERIAL,
        index: 0,
        icon: 'truck-trailer'
      }
    ]
  }
}

</script>
