import { useToast } from 'vue-toast-notification'
import { API, PathSegment } from '@/client/axios'
import type { Page } from '@/model/Page'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'
import { ReportType } from '@/model/Type'
import type { OfferPosition } from '@/model/OfferPosition'

const $toast = useToast();

export async function getOfferPosition(offerId: string, pageSize: number, offset: number, chapterId: string) {
  try {
    const response = await API.getWithParameters<Page<OfferPosition>>(
      PathSegment.OFFERS,
      offerId,
      PathSegment.POSITIONS,
      new Map<string, string>([
        ['pageSize', `${pageSize}`], 
        ['offset', `${offset}`], 
        ['chapterId', chapterId]])
    );
    if (response.key == ResponseKey.OK) return response.data;
    $toast.error(`Offertenposition konnte nicht geladen werden: ${response.message}`);
  } catch (error) {
    $toast.error(`Offertenposition konnte nicht geladen werden: ${Utils.getError(error)}`);
  }
}