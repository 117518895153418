<template>

  <base-site :title="title" :show-button="!!categoryId" button-icon="trash" button-look="secondary-light" @button-action="openModal">

    <base-container class="mb-betweenElements">

      <spinner v-if="categoryLoading" />

      <div v-else>
        <div class="flex items-center justify-between gap-5">
          <div class="flex-1">
            <FormKit type="text" label="Name*" validation="required" v-model="name" />
          </div>
          <button @click="openIconSelectorModal" class="p-5 border selectable">
            <phosphor-icon :icon="icon ? icon : 'prohibit'" />
          </button>
        </div>
        <FormKit type="text" label="Rechnungstext" v-model="invoiceInscription" />
        <FormKit type="select" label="Standart-Einheit" :options="unitOptions" v-model="selectedStandardUnitId" />
        <FormKit type="number" number step="any" label="Standart-Preis" v-model="standardPrice" />
      </div>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Kategorie löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll die Kategorie wirklich gelöscht werden?</confirmation-modal>

      <icon-selector ref="iconSelector" @on-select="onIconSelect" />
    </base-container>

    <div v-if="!categoryLoading" class="flex md:flex-row flex-col">
      <base-button look="primary" :disabled="!validate()" class="md:mr-auto mb-0 md:mx-0 mx-betweenElements" @click="onSubmit">{{submitText}}</base-button>
      <base-button look="secondary-light" class="md:mx-0 md:mt-0 mt-betweenElements mx-betweenElements" @click="onCancel()">Abbrechen</base-button>
    </div>

  </base-site>

</template>

<script setup lang="ts">

import { API, PathSegment } from '@/client/axios'
import { useRoute, useRouter } from 'vue-router'
import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { onMounted, ref } from 'vue'
import type Category from '@/model/Category'
import Spinner from '@/components/generel/Spinner.vue'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { Type } from '@/model/Type'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { FormValidator, Validation } from '@/client/FormValidator'
import Dropdown from '@/components/generel/Dropdown.vue'
import IconSelector from '@/components/generel/IconSelector.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const router = useRouter();
const route = useRoute();
const $toast = useToast();

const title = ref('Kategorie');
const submitText = ref('Speichern');
const categoryId = route.params.id as string;
const category = ref<Category>();
const parentCategoryId = route.query.parentCategoryId as string;
const categoryTypeId = route.query.categoryTypeId as string;
const categoryLoading = ref(true);
const requesting = ref(false);
const icon = ref('');

const name = ref('');
const invoiceInscription = ref<string | undefined>();
const selectedStandardUnitId = ref('');
const standardPrice = ref<number>();

const confirmDeletionModal = ref(ConfirmationModal);
const iconSelector = ref(IconSelector);

const unitOptions = ref(Utils.toSelectOptions(Type.getAllUnits(), 'Keine'));

async function onSubmit() {
  if (!validate()) return;
  const fields: any = {
    name: name.value,
    invoiceInscription: invoiceInscription.value,
    standardUnitId: selectedStandardUnitId.value,
    standardPrice: standardPrice.value,
    icon: icon.value
  };
  if (categoryId) await onUpdate(fields);
  else await onCreate(fields);
}

async function onCreate(fields: any) {
  if (!categoryTypeId) {
    $toast.error('Pfad fehlerhaft');
    return;
  }
  requesting.value = true;
  const response = await API.createDataObject<Category>(PathSegment.CATEGORIES, {
    id: '',
    parentCategoryId: parentCategoryId,
    categoryTypeId: categoryTypeId,
    categoryLevel: 0,
    name: fields.name as string,
    invoiceInscription: fields.invoiceInscription ? fields.invoiceInscription as string : fields.name as string,
    standardUnitId: fields.standardUnitId ? fields.standardUnitId as string : null,
    standardPrice: fields.standardPrice ? fields.standardPrice as number : null,
    icon: fields.icon ? fields.icon as string : 'dot-outline',
    createdAt: 0,
    updatedAt: 0
  }, 'Kategorie');
  requesting.value = false;
  if (!response) return;
  router.go(-1);
}

async function onUpdate(fields: any) {
  const cat = category.value;
  if (!cat) {
    $toast.error('Keine Kategorie geladen');
    return;
  }
  requesting.value = true;
  const response = await API.updateDataObject<Category>(PathSegment.CATEGORIES, {
    id: cat.id,
    parentCategoryId: cat.parentCategoryId,
    categoryTypeId: cat.categoryTypeId,
    categoryLevel: cat.categoryLevel,
    name: fields.name as string,
    invoiceInscription: fields.invoiceInscription ? fields.invoiceInscription as string : fields.name as string,
    standardUnitId: fields.standardUnitId ? fields.standardUnitId as string : null,
    standardPrice: fields.standardPrice ? fields.standardPrice as number : null,
    icon: fields.icon ? fields.icon as string : 'dot-outline',
    createdAt: 0,
    updatedAt: 0
  }, 'Kategorie');
  requesting.value = false;
  if (!response) return;
  router.go(-1);
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.CATEGORIES, categoryId);
  if (success) {
    router.go(-1);
  }
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

async function loadCategory(id: string) {
  categoryLoading.value = true;
  const response = await API.getDataObject<Category>(PathSegment.CATEGORIES, id, 'Kategorie');
  categoryLoading.value = false;
  if (!response) return;
  category.value = response;
  name.value = response.name;
  invoiceInscription.value = response.invoiceInscription;
  selectedStandardUnitId.value = response.standardUnitId ?? '';
  standardPrice.value = response.standardPrice == null ? undefined : response.standardPrice;
  icon.value = response.icon == 'dot-outline' ? '' : response.icon;
}

function validate() {
  return FormValidator.validate([
    {value: name.value, validation: Validation.REQUIRED}
  ])
}

function onIconSelect(selectedIcon: string) {
  icon.value = selectedIcon;
}

function openIconSelectorModal() {
  if (!iconSelector.value) return;
  iconSelector.value.openModal();
}

onMounted(() => {
  if (categoryId) {
    title.value = 'Kategorie bearbeiten';
    submitText.value = 'Speichern';
    loadCategory(categoryId);
  } else {
    title.value = 'Kategorie erstellen';
    submitText.value = 'Erfassen';
    categoryLoading.value = false;
  }
});

</script>

<style scoped>

</style>